
import { defineComponent, ref, PropType, computed, shallowRef, watch, getCurrentInstance } from 'vue'
import { getClientList } from '@api/chisai/GCB2'
import useRouter from '../../../../hooks/useRouter'
import { Project } from '../../../../types/main'
import { MessageVarBody } from '@t/GCB2'
import { MESSAGE_VARS } from '@/vars/GCB2'
import { v4 as uuidv4 } from 'uuid'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import api from '../../../../api/api'

export default defineComponent({
    name: 'MessageVariablesSettings',
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        value: { type: Object as PropType<any>, required: true },
    },
    setup(props, { emit,  }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const messageVars = ref(MESSAGE_VARS)
        const clientList = shallowRef<{ id: string; name: string }[]>([])
        const project = computed<Project>(() => root.$store.getters.projectById(route.value.params.id))

        const availableClientList = computed(() =>
            clientList.value.map(el =>
                varsExceptions.value
                    .filter(ex => ex.varId === activeMessageVarId.value)
                    .map(ex => ex.id)
                    .includes(el.id)
                    ? Object.assign({}, el, { disabled: true })
                    : Object.assign({}, el, { disabled: false })
            )
        )

        const startVarsExceptions = shallowRef<MessageVarBody[]>([])
        const varsExceptions = ref<MessageVarBody[]>([])

        const getMessageExeptionsByVarId = (varId: string) =>
            varsExceptions.value.filter(el => el.varId === varId)
        const activeMessageVarId = ref('{name}')
        const formInput = _.debounce((e: any) => {
            emit('validation-updated', messageVars)
            const updatedDiffCond = (o: MessageVarBody) => o.id + o.varId + o.value
            const deletedDiffCond = (o: MessageVarBody) => o.id + o.varId
            const validCond = (o: MessageVarBody) => o.id && o.value.trim()
            const deleted = _.differenceBy(
                startVarsExceptions.value,
                varsExceptions.value,
                deletedDiffCond
            ).filter(validCond)
            const updated = _.differenceBy(
                varsExceptions.value,
                startVarsExceptions.value,
                updatedDiffCond
            ).filter(validCond)
            emit(
                'input',
                Object.assign({}, props.value, {
                    messageVars: { deleted, updated },
                })
            )
        }, 250)
        const clear = () => (root.$refs as any).form.resetValidation()

        const addExeption = (varId: string) =>
            varsExceptions.value.push({
                _id: uuidv4(),
                id: null,
                value: '',
                varId,
            })
        const deleteExeption = (_id: string) =>
            (varsExceptions.value = varsExceptions.value.filter((el: any) => el._id !== _id))

        const activeList = computed(() => {
            if (activeMessageVarId.value === '{name}') {
                return availableClientList.value
            }
            return [{ id: '_DEFAULT_MESSAGE_VAR', name: 'По умолчанию' }]
        })
        const onCreated = async () => {
            const [_clientList, _varsExceptions] = await Promise.all([
                getClientList({ projectId: project.value.id }).then(d => d.data!),
                api.gcb2
                    .getMessageVarsValues({
                        projectId: project.value.id,
                        varIds: MESSAGE_VARS.map(el => el.id),
                    })
                    .then(res => res.data!.map(el => ({ ...el, _id: uuidv4() }))),
            ])
            clientList.value = _clientList.concat({ id: '_DEFAULT_MESSAGE_VAR', name: 'По умолчанию' })

            startVarsExceptions.value = _.cloneDeep(_varsExceptions)
            varsExceptions.value = _.cloneDeep(_varsExceptions)
        }
        onCreated()
        return {
            formInput,
            clear,
            getMessageExeptionsByVarId,
            varsExceptions,
            messageVars,
            activeMessageVarId,
            clientList,
            availableClientList,
            addExeption,
            deleteExeption,
            activeList,
        }
    },
})
