
import Vue, { PropType } from 'vue'
import * as t from '@/store/chisai/GCB2/actionTypes'
import TextField from '../../../inputs/TextField.vue'
import { ACTIVATION_DESCRIPTION_MAX_LENGTH, ACTIVATION_NAME_MAX_LENGTH } from '../../../../vars/GCB2'
import _, { uniqueId } from 'lodash'
import { Drag, Drop, DropMask } from 'vue-easy-dnd'
import AddMessagePartButton from './AddMessagePartButton.vue'
import SmileSelector from "../../../../components/ui/SmileSelector.vue"
import { Activation, EditedActivation, IGCB2Connector } from '../../../../types/GCB2'
import MessageCollectionButton from './MessageCollectionButton.vue'
import api from '../../../../api/api'
import messages from '@dataheroes/messages'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import { Project } from '../../../../types/main'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import cfg from '../../../../../config'

export default Vue.extend({
    components: {
        TextField,
        AddMessagePartButton,
        Drag,
        Drop,
        MessageCollectionButton,
        CustomActivatorSelect,
        SmileSelector
    },
    props: {
        value: { type: Object as PropType<EditedActivation> },
    },
    data: () => ({
        activePartNumber: 1,
        nameRules: [
            (v: any) => !!v || 'Обязательно',
            (v: any) =>
                v.length <= ACTIVATION_NAME_MAX_LENGTH || `Меньше ${ACTIVATION_NAME_MAX_LENGTH} символов`,
        ],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        allowedMessageVarsIds(): string[] {
            const vars = [
                'client.name',
                'user.name',
                'bot.salonName',
                'bot.salonPhone',
                'bot.salonAddress',
                'bot.feedbackLink',
                'simple.eventDate',
                'simple.onlineRecordLink',
                'simple.eventDate',
            ]
            if (cfg.audienceWithReturnAfterVar.includes(this.value.audienceId!)) {
                vars.push('returnAfter')
            }
            return vars
        },
        GCB2Connector(): IGCB2Connector {
            return this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.GCB2
            ) as IGCB2Connector
        },
        messagePartsByPartNumber(): any[] {
            const groupedMessageParts = _.groupBy(
                this.value.message.messageParts.filter(el => !el._deleted),
                'partNumber'
            )
            return Object.keys(groupedMessageParts).map(partNumber => ({
                partNumber: Number(partNumber),
                messageParts: groupedMessageParts[partNumber],
            }))
        },
        activeMessagePartsByPartNumber(): any[] {
            return (
                this.messagePartsByPartNumber.find(el => el.partNumber === this.activePartNumber)
                    ?.messageParts || []
            )
        },
        messageVarsList(): any[] {
            return messages.vars.vars
                .filter(el => this.allowedMessageVarsIds.includes(el.id))
                .map(el => ({ id: _.first(el.aliases), name: _.first(el.aliases) }))
        },
        showMessageCollection(): Boolean {
            return this.GCB2Connector.showMessageCollection
        },
    },
    methods: {
        insertVar(messagePart: any, varALias: string, textareaIndex: number) {
            const textareas = this.$refs['message-textareas'] as any[]
            const el = textareas[textareaIndex].$refs.input
            const [start, end] = [el.selectionStart, el.selectionEnd]
            messagePart.textVariant = [
                messagePart.textVariant.slice(0, start),
                ` {${varALias}}`,
                messagePart.textVariant.slice(start),
            ].join('')
        },

        // Вообще думаю стоит сделать общую функцию для этой, и функции выше
        // но пока не буду во избежание неприятностей
        insertEmoji(messagePart: any, emoji: string, textareaIndex: number) {
            const textareas = this.$refs['message-textareas'] as any[]
            const el = textareas[textareaIndex].$refs.input
            const [start, end] = [el.selectionStart, el.selectionEnd]
            messagePart.textVariant = [
                messagePart.textVariant.slice(0, start),
                `${emoji}`,
                messagePart.textVariant.slice(start),
            ].join('')
        },

        formInput(e: any) {
            this.$emit('validation-updated', e)
        },
        clear() {
            ;(this.$refs as any).form.resetValidation()
        },
        addMessagePart(partNumber: number) {
            const newMessageParts = [...this.value.message.messageParts].concat([
                { partNumber, textVariant: '', _id: uniqueId() },
            ])
            this.activePartNumber = partNumber
            this.$emit(
                'input',
                Object.assign({}, this.value, {
                    message: {
                        ...this.value.message,
                        messageParts: newMessageParts,
                    },
                })
            )
        },
        deleteMessagePart(messagePart: any) {
            const message = this.value.message
            const newMessageParts = [...message.messageParts]
            const messageToDelete = newMessageParts.find((el: any) => el._id === messagePart._id)
            const messagePartsWithThisPartNumber =
                this.messagePartsByPartNumber.find(el => el.partNumber === messagePart.partNumber)
                    .messageParts || []
            if (messagePartsWithThisPartNumber.length === 1) {
                //части сообщений, у которых номер части больше чем номер части удаляемой
                const messagePartsWithPartNumberBiggerThanThis = newMessageParts.filter(
                    el => el.partNumber > messagePart.partNumber
                )
                messagePartsWithPartNumberBiggerThanThis.forEach(el => {
                    el.partNumber = el.partNumber - 1
                })
            }
            messageToDelete!._deleted = true
            this.$emit(
                'input',
                Object.assign({}, this.value, {
                    message: {
                        ...this.value.message,
                        messageParts: newMessageParts,
                    },
                })
            )
        },
        setMessage(_message: any) {
            const currentMessage = this.value.message
            const message = _.cloneDeep(_message)
            message.id = currentMessage.id
            message.messageParts = currentMessage.messageParts
                .map(el => ({ ...el, _deleted: true }))
                .concat(message.messageParts)
            this.$emit(
                'input',
                Object.assign({}, this.value, {
                    message,
                })
            )
        },
        updateMessagePartPartNumber(e: any, newPartNumber: number) {
            const messagePart = e.data
            const message = this.value.message
            const newMessageParts = [...message.messageParts]
            const indexToUpdate = newMessageParts.findIndex((el: any) => el._id === messagePart._id)
            const messagePartsWithThisPartNumber =
                this.messagePartsByPartNumber.find(el => el.partNumber === messagePart.partNumber)
                    .messageParts || []
            const updateMessagePart = () => {
                newMessageParts.splice(
                    indexToUpdate,
                    1,
                    Object.assign({}, messagePart, { partNumber: newPartNumber })
                )
            }
            if (messagePartsWithThisPartNumber.length === 1) {
                if (messagePart.partNumber > newPartNumber) {
                    const messagePartsWithPartNumberBiggerThanThis = newMessageParts.filter(
                        el => el.partNumber > messagePart.partNumber
                    )
                    messagePartsWithPartNumberBiggerThanThis.forEach(el => {
                        el.partNumber = el.partNumber - 1
                    })
                    updateMessagePart()
                } else {
                    updateMessagePart()
                    const messagePartsWithPartNumberBiggerThanThis = newMessageParts.filter(
                        el => el.partNumber > messagePart.partNumber
                    )
                    messagePartsWithPartNumberBiggerThanThis.forEach(el => {
                        el.partNumber = el.partNumber - 1
                    })
                }
            } else {
                updateMessagePart()
            }

            this.$emit(
                'input',
                Object.assign({}, this.value, {
                    message: {
                        ...this.value.message,
                        messageParts: newMessageParts,
                    },
                })
            )
        },
    },
    async created() {},
})
