import { render, staticRenderFns } from "./MessageSettings.vue?vue&type=template&id=7387036f&scoped=true"
import script from "./MessageSettings.vue?vue&type=script&lang=ts"
export * from "./MessageSettings.vue?vue&type=script&lang=ts"
import style0 from "./MessageSettings.vue?vue&type=style&index=0&id=7387036f&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7387036f",
  null
  
)

export default component.exports