
import {
    defineComponent,
    ref,
    PropType,
    computed,
    shallowRef,
    watch,
    onMounted,
    onBeforeMount,
    getCurrentInstance,
} from 'vue'
import useRouter from '../../../../hooks/useRouter'
import { Project } from '../../../../types/main'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import TimeInput from '../../../inputs/TimeInput.vue'
import { EditedActivation } from '../../../../types/GCB2'
import { timezones } from '@/vars/timezones'
import { checkInputIsIntNumber } from '../../../../utils'
import api from '../../../../api/api'
import messages from '@dataheroes/messages'
import SpinnerLoader from '../../../SpinnerLoader.vue'
export default defineComponent({
    name: 'AutomailSettings',
    components: { BaseInputOutlined, CustomActivatorSelect, TimeInput, SpinnerLoader },
    props: {
        value: { type: Object as PropType<EditedActivation>, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const project = computed<Project>(() => root.$store.getters.projectById(route.value.params.id))

        const loading = ref(true)

        const timezonesList = timezones.map(el => ({
            id: el.offset.replace(/[^0-9]/g, ''),
            name: el.cities.length ? `${el.timezone} (${el.cities.slice(0, 3).join(', ')})` : el.timezone,
        }))
        const formInput = (e: any) => {
            const isValid = true
            emit('validation-updated', isValid)
        }

        onBeforeMount(async () => {
            loading.value = true
            const messagingConnector = messages.getMessagingConnector(project.value.connectors)
            const batchModeSettings = await api.massender
                .getLimitSettings({
                    projectId: project.value.id,
                    connectorType: messagingConnector!.connectorType,
                })
                .then(res => res.data)

            props.value.batchModeSettings = batchModeSettings
            loading.value = false
        })
        return { formInput, timezonesList, checkInputIsIntNumber, loading, set: _.set }
    },
})
