
import { defineComponent, ref, PropType, computed, watch, onMounted } from 'vue'
import useRouter from '../../../../hooks/useRouter'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import { CommunicationTag, EditedActivation } from '../../../../types/GCB2'
import { useProject } from '../../../../hooks/useProject'
import SpinnerLoader from '../../../SpinnerLoader.vue'
import api from '@/api/api'
import { v4 as uuidv4 } from 'uuid'
export default defineComponent({
    name: 'CommunicationTagSettings',
    components: { BaseInputOutlined, CustomActivatorSelect, SpinnerLoader },
    props: {
        value: { type: Object as PropType<EditedActivation>, required: true },
    },
    setup(props, { emit }) {
        const { route } = useRouter()
        const { project } = useProject()
        const loading = ref(true)

        const addTag = () => {
            props.value.communicationTags.push({ id: uuidv4(), name: '', projectId: project.value.id })
        }
        const removeCommunicationTag = (tagId: string) => {
            props.value.communicationTags = props.value.communicationTags.filter(el => el.id !== tagId)
        }
        onMounted(async () => {
            loading.value = true

            const communicationTags = await api.gcb2
                .getCommunicationTags(project.value.id)
                .then(res => res.data!.filter(el => !el.deletedAt))

            props.value.communicationTags = communicationTags
            loading.value = false
        })
        return { addTag, removeCommunicationTag, loading }
    },
})
