
import Vue, { defineComponent, onMounted, ref } from 'vue'
import BaseInputOutlined from "@root/src/components/inputs/BaseInputOutlined.vue"

export default Vue.extend({
    components : {BaseInputOutlined},
    data : () => ({
        warn : '',
        testModePhone : ""
    }),
    props: {
        value: { type: Object },
        mode: { type: String },
    },
    watch : {
        testModePhone(v) {
            if (!v) {
                this.warn = "Поле должно быть заполнено"
                this.value.testModePhone = ""
            } else if (!/^\+?[1-9]\d{9,}$/.test(v)) {
                this.warn = "Некорректный номер телефона"
                this.value.testModePhone = ""
            } else {
                this.warn = ""
                this.value.testModePhone = v.replace("+", "")
            }
        }
    },
    mounted() {
        if (!this.value.mode) {
            this.value.mode = "Боевой"
        }
        this.testModePhone = this.value.testModePhone
    }
})
